import React from 'react';

const IntroForm = () => {
  return (
    <div>
      <iframe 
        className="gform"
        title="Google Form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSekarr2aq42AKaINcYxlstUi8Ns0WmR8tJlMY2vLjgwB65OWw/viewform?embedded=true"
        width="100%"
        height="100%"
        frameBorder="0"
      >

      </iframe>
    </div>
  );
};

  export default IntroForm