import { Container } from '@mui/material'
import React, { Component } from 'react'
import Header from '../common/header'
import BookNow from '../common/book'
import Links from '../common/links'

class Home extends Component {
    render() {
        return (
            <div>
                <div style={{ height: '100vh', overflow: 'hidden', scrollSnapAlign: 'start' }}> 
                    <Header>
                    </Header>
                    <Container style={{ display: 'flex', height: '90VH', justifyContent:'center' }} >
                        <img src={`${process.env.PUBLIC_URL}/Main01.webp`} alt='Nutricionista Kragujevac - Dušan Aragonski biohemičar stručnjak za ishranu' className="main-image" />
                        <div className="right-column-home">
                        <h1 style={{zIndex:2, marginTop:'2vH',right:'0', position:'relative'}} className="main">Prilika za promenu navika</h1>
                            <div style={{ position:'relative'}}>
                                <BookNow/>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexWrap:'wrap', position: 'absolute', bottom:'0', right:'0', marginBottom:'20px' ,marginRight:'20px'}} className='hide-element-on-big'><Links></Links></div>    
                    </Container>
                </div>
                
            </div>
        )
    }
}

export default Home