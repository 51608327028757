import React from 'react';
import Header from '../common/header';
import { Container } from '@mui/system';
import CommonQuestions from './question_box';
import BookNow from '../common/book';
import { Helmet } from 'react-helmet';

const FrequentlyAskedQuestions = () => {
    return (
        <div>
            <Header />
            <h1 style={{ fontSize: '3.2rem', textShadow: '3px 3px 3px #214751', marginBottom: '3VH' }}>Najčešće postavljena pitanja</h1>
            <Container>
                <CommonQuestions />
                <div style={{ position: 'relative', display: 'block', paddingBottom: '20px' }}>
                    <BookNow />
                </div>
            </Container>
        </div>
    );
};

export default FrequentlyAskedQuestions;
