import React, { useState } from 'react';
import { Collapse, Container, Box, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import faqData from './questions_text.json';

const CommonQuestions = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Postavlja otvoreno stanje za trenutno pitanje
    };

    return (
        <Container>
            {faqData.map((item, index) => (
                <Box key={index} sx={{ marginBottom: '20px', border: '1px solid #d1d1d1', borderRadius: '8px', padding: '10px' }}>
                    <Button
                        fullWidth
                        onClick={() => handleToggle(index)}
                        sx={{
                            textAlign: 'left',
                            color: '#d67729',
                            fontSize: '1.2rem',
                            justifyContent: 'space-between',
                            lineHeight:'1.2',
                            padding: '10px 20px',
                            textTransform: 'none',
                        }}
                        endIcon={openIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        {item.question}
                    </Button>
                    <Collapse in={openIndex === index}>
                        <Box sx={{ padding: '10px 20px', backgroundColor: '#fcf9f3', borderRadius: '8px' }}>
                            <Typography
                                variant="body1"
                                component="div"
                                dangerouslySetInnerHTML={{ __html: item.answer }}
                            ></Typography>
                        </Box>
                    </Collapse>
                </Box>
            ))}
        </Container>
    );
};

export default CommonQuestions;
