import * as React from 'react';
import { Container } from '@mui/system';
import Header from '../common/header';
import BookButton from '../common/book'
import { Helmet } from 'react-helmet'

import FreeConsultationImage from '../Images/free_consultation.jpg';
import IndividualConsultationImage from '../Images/individual_consultation.jpg';
import BasicPackageImage from '../Images/basic_package.jpg';
import IntensivePackageImage from '../Images/intensive_package.jpg';
import WebinarsImage from '../Images/webinars.jpg';

const services = [
    { id: "besplatne-konsultacije", title: "Besplatne online konsultacije", image: FreeConsultationImage },
    { id: "individualne-konsultacije", title: "Individualne konsultacije", image: IndividualConsultationImage },
    { id: "osnovni-paket-konsultacija", title: "Osnovi paket konsultacija", image: BasicPackageImage },
    { id: "intenzivni-paket-konsultacija", title: "Intenzivni paket konsultacija", image: IntensivePackageImage },
    { id: "predavanja-i-vebinari", title: "Predavanja i Webinari", image: WebinarsImage }
];

// Single service block component
const ServiceBlock = ({ id, title, image }) => {
    const handleScroll = () => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="service-visuals" onClick={handleScroll} style={{ cursor: 'pointer' }}>
            <img alt='usluge nutricioniste koje nudimo besplatno plaćeno pojedinačno paketi' style={{ width: '100%', borderRadius: '15px', border: '2px solid #ccc', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }} src={image} />
            <p style={{ color: 'white', fontSize: '18px', textAlign: 'center', marginTop: '10px' }}>{title}</p>
        </div>
    );
};

// Main service page component
const ServicePage = () => {
    return (
        <div>
            <Header/>
            <Container>
                <h1 style={{fontSize: '2.5rem' }} className="titles">Kako jednostavne promene mogu da dugotrajno poboljšaju tvoju ishranu i zdravlje</h1>
                <p className="white_text">Kao što naslov kaže, ono što ti nudim jeste dugotrajno rešenje tvojih problema sa ishranom i zdravljem. Odmah mogu da ti obećam da će proces biti veoma lak i jednostavan, toliko da ćeš se na kraju zapitati „Zašto ovo nisam pre uradio?”. Na žalost teško je sam promeniti sebe, zato sam tu da ti dam motivaciju i savete kako bi ostvario svoje ciljeva. Moji pristup je kolaborativan, jer sam svestan da ti najbolje poznaješ sebe, pa je na meni da ti pomognem da sam dođeš do najboljeg načina da izbalansiraš svoji život sa održivim dobrim navikama. U daljem tekstu videćeš koje sve usluge nudim.</p>

                <Container style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px', padding: '10px' }}>
                    {services.map((service, index) => (
                        <ServiceBlock key={index} id={service.id} title={service.title} image={service.image} />
                    ))}
                </Container>
            <div className="white_text">
            <h2 id="besplatne-konsultacije">Besplatne online konsultacije</h2>
                <p>Da li te zanima kako ti moje konsultacije mogu pomoći da ostvariš svoje ciljeve?</p>
                <p>Zakaži svoj besplatan online sastanak u trajanju od 15 minuta! Na ovom kratkom sastanku moćićemo da se:</p>
                <ul>
                    <li><strong>Upoznamo</strong>: Popričamo o tvojim ciljevima, zdravlju i životu</li>
                    <li><strong>Istražimo problem</strong>: Postaviću ti nekoliko pitanja kako bih razumeo izazove pred tobom</li>
                    <li><strong>Upoznaš sa mojim pristupom</strong>: Objasniću ti moje metode, pristup i benefite rada sa mnom</li>
                    <li><strong>Postaviš mi pitanja</strong>: Imaćeš priliku da mi postaviš bilo koje pitanje vezano za moje usluge</li>
                </ul>
                <p>Ovaj besplatan prvi sastanak te ne obavezuje na kasniju saradnju i dobra je prilika da proveriš da li možemo da uspešno radimo zajedno na ostvarenju tvojih nutritivnih i zdravstvenih ciljeva.</p>

            <h2 id="individualne-konsultacije">Individualne konsultacije</h2>
                <p>Spreman si da poboljšaš svoju ishranu već danas? Jedan na jedan konsultacije sa mnom će ti pomoći da ostvariš svoje ciljeve. Nezavisno od toga da li želiš da se razgovaramo uživo ili radimo online, konsultacije traju sat vremena i tokom njih možeš očekivati:</p>
                <ul>
                    <li><strong>Analizu trenutnog stanja</strong>: Počećemo od razmatranja tvoje zdravstvene istorije, životnog stila i navika u ishrani. Takođe, izmerićemo kilažu i procente masti i mišića u telu, kako bismo postavili jasnu sliku početne tačke.</li>
                    <li><strong>Postavljanje cilja</strong>: Zajedno ćemo razmisliti o realističnom i jasnom cilju koji se uklapa u tvoju viziju sebe</li>
                    <li><strong>Pravljenje plana</strong>: Kako bismo ostvarili tvoj cilj neophodno je da osmislimo korake koji će nas do njega dovesti. Predložiću ti nekoliko opcija i pružiti ti preporuke za izbor hrane, veličinu porcija i druge stvari koje podržavaju tvoj napredak</li>
                    <li><strong>Motivacija i podrška</strong>: Istražićemo tvoju motivaciju i izgraditi samopouzdanje kako bi nastavio ka svojim ciljevima.</li>
                </ul>
                <p>Dok jedne konsultacije mogu da pruže vredno znanje i budu početna tačka za promenu, moja preporuka je da isplaniraš nekoliko sastanaka kako bismo postigli trajnu promenu.</p>
                <p><strong>Cena individualnih konultacija (1h) je 4.000RSD ili 40EUR za uplate iz inostranstva.</strong></p>

            <h2>Paketi konsultacija</h2>
                <p>Spreman si da se posvetiš svom zdravlju i postigneš dugorične rezultate. Pomoćiću ti mojim sveobuhvatnim paketima konsultacija. Paketi nude strukturu i podršku i pomažu ti da postaneš ekspert za svoju ishranu i zdravlje.</p>

                <h3 id="osnovni-paket-konsultacija">Osnovni paket</h3>
                <ul>
                    <li>6 individualnih konsultacija u trajanju od 1 sata</li>
                    <li>Individualni plan koraka za unapređenje ishrane</li>
                    <li>Podršku i vođstvo između konsultacija</li>
                    <li>Edukativni materijal</li>
                    <li>Fokus na pravljenju održive promene i ostvarivanje ciljeva</li>
                </ul>
                <p><strong>Cena: 20.000RSD ili 200EUR za uplate iz inostranstva.</strong></p>

                <h3 id="intenzivni-paket-konsultacija">Intenzivni paket</h3>
                <ul>
                    <li>Sve što uključuje Osnovni paket, PLUS:</li>
                    <li>6 dodatnih 15-minutnih poziva između konsultacija za veći nivo podrške</li>
                    <li>Prioritet u zakazivanju sastanaka i brže vreme odgovora</li>
                </ul>
                <p><strong>Cena: 32.000RSD ili 320EUR za uplate iz inostranstva.</strong></p>

                <h3>Zašto odabrati Pakete?</h3>
                <ul>
                    <li>Posvećenost: Investiranje u paket pokazuje tvoju posvećenost pravljenu trajne promene</li>
                    <li>Isplativost: Paketi daju značajnu uštedu u odnosu na pojedinačne konsultacije</li>
                    <li>Podrška: Konzistentna podrška i vođstvo na tvom putu promene navika</li>
                    <li>Postani ekspert za sebe: Do kraja trajanja paketa imaćeš znanje i samopouzdanje da donosiš dobre i informisane odluke o svojoj nutriciji ostatak života</li>
                </ul>
                <p>Osnovni paket je idealan za motivisane i odlučne ljude kojima znači da dobiju podršku i vođstvo. Intenzivan paket namenjen je onima koji žele češći razgovor i više praćenja tokom celokupnog procesa.</p>

                <h2 id="predavanja-i-vebinari">Predavanja i Webinari</h2>
                <p>Ohrabri svoje zaposlene da brinu o svom zdravlju i ishrani uz pomoć mojih informativnih i inspirišućih predavanja o nutriciji i dobrostanju. Nudim prilagođena predavanja koja se mogu dobro uklopiti u akcije tvoje kompanije poput:</p>
                <ul>
                    <li>Ishrana u modernom svetu (Ishrana i navike I)</li>
                    <li>Psihologija ishrane (Ishrana i navike II)</li>
                    <li>Hrana za doba anksioznosti</li>
                    <li>Nutricija za produktivnost</li>
                </ul>
                <p>Kontaktiraj me kako bismo popričali o potrebama tvoje firme i dogovorili se oko detalja predavanja ili vebinara.</p>
                </div>
                <div style={{paddingBottom:'30px'}}>
                <BookButton/>
                </div>
        </Container>
</div>
    );
}

export default ServicePage;
