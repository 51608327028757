import { Container } from '@mui/system';
import React from 'react';
import Header from '../common/header'
import Links from '../common/links'

import { SquareImage, SquareImageLeft, RectangleImage } from '../food_science/blog_visuals';
import FirstImage from './landing_images/img1.jpg'
import SecondImage from './landing_images/img2.jpg'
import ThirdImage from './landing_images/img3.jpg'

const Companies = () => {
    
return (
    <div style={{ minHeight: '100VH' }}>
        <div>
        <Header />
        <h1 style={{ fontSize: '2.8rem', textShadow: '3px 3px 3px #214751', marginBottom: '3VH'}}>Unapredite zdravlje, energiju i produktivnost svojih zaposlenih kroz stručna predavanja i nutricionističke konsultacije</h1>
        </div>
        <div style={{ width: '100HV', backgroundColor: '#fcf9f3' }}>
                <Container style={{}}>
                    <p>&nbsp;</p>
                    <RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
                    <p>Pružite svojim zaposlenima priliku da unaprede svoje zdravlje i poboljšaju radne performanse uz prilagođene nutricionističke programe i podršku. Kroz edukativna predavanja, webinare i personalne konsultacije, pomažemo zaposlenima da bolje upravljaju energijom, smanje stres i razviju dugoročne zdrave navike.</p>
                    <h3><strong>Koristi za firmu i zaposlene:</strong></h3>
                    <ul>
                        <li>
                            <p><strong>Povećana produktivnost i fokus</strong>: Praktični saveti o ishrani i navikama omogućavaju zaposlenima da poboljšaju nivo energije i mentalnu jasnoću, čime doprinosimo povećanju efikasnosti na radnom mestu.</p>
                        </li>
                        <li>
                            <p><strong>Smanjenje stresa i podizanje otpornosti</strong>: Edukacija o vezi između ishrane i stresa pomaže zaposlenima da se bolje nose sa svakodnevnim izazovima, smanjujući stres i povećavajući otpornost.</p>
                        </li>
                        <li>
                            <p><strong>Dugoročne koristi za zdravlje</strong>: Kroz naš program zaposleni stiču znanja o zdravoj ishrani i održivim navikama, što može doprineti njihovom sveukupnom zdravlju i blagostanju.</p>
                        </li>
                        <li>
                            <p><strong>Povećano zadovoljstvo i briga o zaposlenima</strong>: Angažovanjem nutricioniste pokazujete brigu o dobrobiti svojih zaposlenih, što doprinosi njihovom zadovoljstvu i motivaciji.</p>
                        </li>
                    </ul>

                   
                    <SquareImageLeft src={SecondImage} alt="Description">
                    <h3>Naša ponuda:</h3>
                    <ul>
                    
                    <li><strong>Predavanja i vebinari</strong> Naša predavanja i vebinari su prilagođeni različitim potrebama zaposlenih i tematski pokrivaju širok spektar tema koje su ključne za balansiran život, uključujući upravljanje stresom, izgradnju pozitivnih navika, bolji odnos sa hranom. Predavanja se mogu održati uživo ili onlajn, omogućavajući fleksibilnost koja odgovara vašem timu.</li>
                    <li><strong>Personalizovane konsultacije za zaposlene</strong> Pored predavanja, zaposleni imaju priliku da zakažu individualne nutricionističke konsultacije po posebnoj ceni, uz mogućnost popusta ili besplatnih termina za one firme koje žele dugoročno partnerstvo. Ove konsultacije pružaju priliku za detaljan razgovor o ličnim ciljevima, kao i podršku u kreiranju održivih promena u ishrani.</li>
                    </ul>
                    </SquareImageLeft>
                    <h2>Detalji o predavanjima</h2>
                    <p>Naša predavanja osmišljena su tako da gostima pruže praktične savete i korisne informacije koje mogu lako primeniti u svakodnevnom životu. Predavanja su dostupna uživo u vašem hotelu ili onlajn, preko platforme po vašem izboru, što omogućava fleksibilnost i pristupačnost za sve tipove gostiju.</p>
                    <p><strong>„Ishrana u doba anksioznosti - veza stresa i hrane“ (1h)</strong>
                    </p>
                    <p>Ovo predavanje se fokusira na razumevanje stresa, njegovih uzroka i različitih vrsta. Vaši zaposleni će naučiti kako stres utiče na organizam i kako se kroz pravilnu ishranu i nekoliko lakih metoda mogu ublažiti njegovi negativni efekti.</p>
                    <SquareImage src={ThirdImage} alt="Description">
                    <h3>„Nije tvoja krivica, jeste tvoja odgovornost“ (1h)</h3>
                    <p>Na ovom predavanju vaši zaposleni moći će da saznaju kako funkcioniše njihovo telo na biohemijskom nivou i kako svakodnevne odluke utiču na njihovo zdravlje i nivo energije. Na jednostavan i razumljiv način, ovo predavanje pruža praktične savete za poboljšanje ishrane i navika.</p>
                    <h3>„Ishrana u modernom svetu - ishrana i navike I“ (2h)</h3>
                    <p>Tokom ovog predavanja razgovaraćemo o izazovima moderne ishrane i uticaju industrijski obrađene hrane na naše zdravlje. Podsetićemo se najčešćih mitova o ishrani i dati korisne savete za izgradnju boljih navika u skladu sa modernim tempom života.</p>
                    
                    <h3>„Psihologija ishrane - ishrana i navike II“ (2h)</h3>
                    <p>U ovom predavanju istražujemo psihološke faktore koji utiču na ishranu, uključujući ulogu emocija, stresa i svakodnevnih navika. Ovo će pomoći zaposlenima da razviju zdrav odnos prema hrani i pronađu održiva rešenja za izgradnju pozitivnih navika.</p>
                    </SquareImage>
                    <h2>Detalji o personalnim sastancima</h2>
                    <p>Personalni sastanci omogućavaju zaposlenima prilagođeno nutricionističko savetovanje gde mogu definisati sopstvene ciljeve u vezi sa ishranom i zdravljem i raditi na njima uz stručno vođenje. Sastanci uključuju postavljanje ciljeva, prilagođene savete za ishranu i plan za održive promene. Mogu se održavati uživo ili onlajn.</p>
                    <p><strong>Praktični alati nakon sastanaka:</strong> Na kraju svakog sastanka zaposleni dobijaju praktične preporuke i savete koje mogu lako primeniti. To može biti lista jednostavnih ciljeva, mapa puta promena ili ključni saveti o problematičnim aspektima ishrane i navika.</p>
                    <p><strong>Trajanje i preporuka:</strong> Jedan sastanak traje sat vremena, a za optimalne rezultate preporučujemo minimum tri sastanka, dok su dugoročne promene najefikasnije uz redovne sastanke i konsultacije.</p>
                    
                    <h2>Cene usluga</h2>

                            <p><strong>Predavanja:</strong></p>
                            
                            <ul>
                                <li>
                                    <p>Jednosatno predavanje: <b>150 EUR</b></p>
                                </li>
                                <li>
                                    <p>Dvosatno predavanje: <b>300 EUR</b></p>
                                </li>
                            </ul>

                            <p><strong>Personalni sastanci:</strong></p>

                            <ul>
                                <li>
                                    <p>Cena po sastanku (1h): <b>40 EUR</b></p>
                                </li>
                                <li>
                                    <p>Dugoročna saradnja: Otvoreni smo za dogovor o popustima i besplatnim terminima za firme koje žele dugoročnu saradnju i redovno angažovanje.</p>
                                </li>
                            </ul>
                    
                    <h2>Investirajte u zdravlje i zadovoljstvo svojih zaposlenih!</h2>
                    <p>Kontaktirajte nas kako bismo kreirali najbolji program za vašu firmu i podržali vaše zaposlene na putu ka zdravlju i dugoročnom uspehu.</p>
                    <p><strong>Kontakt informacije:</strong>
                    </p>
                    <ul>
                        <li>
                            <p><strong>E-mail:</strong>  <a target="_blank" rel="noopener noreferrer nofollow" href="mailto:dusan@aragonski.rs">dusan@aragonski.rs</a>
                            </p>
                        </li>
                        <li>
                            <p><strong>Telefon:</strong> +381-66/58-22-799</p>
                        </li>
                    </ul>
                     
                </Container>
                <div style={{ display: 'flex', flexWrap:'wrap',  justifyContent:'center' }}> <Links></Links> </div>
               </div>
    </div>
)
}
export default Companies;