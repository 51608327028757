import React, { Component } from 'react'
import Header from '../common/header'
import ScienceBlock from './science_block'
import Blogs from './blog_texts.json'
import { Container } from '@mui/material'

class Science extends Component {
    render() {
        const reversedBlogs = [...Blogs.articles].reverse();
        return (
            
            <div style={{ minHeight: '100VH' }}>

                <Header>
                </Header>
                <h1 style={{fontSize: '4rem' }} className="main">Blog</h1>
                <div style={{display:'flex'}}>
                <Container spacing={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
            {reversedBlogs ? reversedBlogs.map(({ post_no, title, image, text_short }) => (
              <ScienceBlock text={text_short} title={title} image={image} key={post_no} identity={post_no} />
            )) : Blogs.articles.map(({ post_no, title, image, text_short }) => (
              <ScienceBlock text={text_short} title={title} image={image} key={post_no} identity={post_no} />
            ))}
            </Container>
                </div>
            
            </div>
        )
    }
}

export default Science