import { Container } from '@mui/system';
import React from 'react';
import Header from '../common/header'
import Links from '../common/links'

import { SquareImage, SquareImageLeft, RectangleImage } from '../food_science/blog_visuals';
import FirstImage from './landing_images/img21.jpg'
import SecondImage from './landing_images/img22.jpg'
import ThirdImage from './landing_images/img3.jpg'
const Hotels = () => {
    
return (
    <div style={{ minHeight: '100VH' }}>
        <div>
        <Header />
        <h1 style={{ fontSize: '2.8rem', textShadow: '3px 3px 3px #214751', marginBottom: '3VH'}}>Obogatite iskustvo gostiju kroz stručna predavanja i nutricionističke konsultacije</h1>
        </div>
        <div style={{ width: '100HV', backgroundColor: '#fcf9f3' }}>
                <Container style={{}}>
                    <p>&nbsp;</p>
                    <RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
                    <p>Pružite svojim gostima priliku da unaprede svoje zdravlje, energiju i zadovoljstvo boravkom u vašem hotelu kroz stručna predavanja i prilagođene nutricionističke konsultacije.</p>
                    <h2>Benefiti za vaše goste:</h2>
                    <ul>
                        <li>
                            <p><strong>Kontrola stresa i opuštanje:</strong> Predavanja o stresu nude gostima praktične metode za smanjenje napetosti kroz male, ali moćne promene u navikama.</p>
                        </li>
                        <li>
                            <p><strong>Povećanje energija:</strong> Praktični saveti o ishrani i navikama pomažu vašim gostima da se osećaju energičnije i spremno za dnevne aktivnosti, bilo da su na poslovnom putovanju ili odmoru.</p>
                        </li>
                        <li>
                            <p><strong>Edukacija i lični razvoj:</strong> Predavanja i konsultacije daju gostima nove uvide i veštine za brigu o sebi, čime dodatno obogaćujete njihovo iskustvo u hotelu.</p>
                        </li>
                        <li>
                            <p><strong>Dugoročna korist:</strong> Gosti stiču znanje o navikama i njihovom uticaju na raspoloženje i zdravlje, koja će im značiti i nakon boravka u vašem hotelu, čime im pružate trajnu vrednost i ostavljate dugotrajan pozitivan utisak.</p>
                        </li>
                    </ul>
                    <SquareImageLeft src={SecondImage} alt="Description">
                    <h3>Jedinstvenost mog pristupa</h3>
                    <p>Kombinujući znanja iz oblasti biohemije sa višegodišnjim iskustvom u vođenju klijenata ka održivim promenama u ishrani uspeo sam da kreiram jedinstven pristup koji pokazuje najbolje rezultate. Moj pristup ne zahteva odricanje, već nudi realne i prilagođene metode koje pomažu ljudima da se osećaju dobro i postignu dugoročne rezultate. Predavanja su interaktivna i jedinstvena, a individualne konsultacije omogućavaju gostima da se detaljno posvete svojim ciljevima i navikama kroz personalizovan razgovor.</p>
                    <h3><strong>Šta nudimo?</strong></h3>
                    <p>Naša predavanja su prilagođena različitim potrebama gostiju - od poslovnih ljudi koji žele da bolje upravljaju stresom i energijom, do gostiju koji traže praktične savete za zdraviji životni stil.</p>
                    <p>Pored predavanja, gosti imaju priliku da zakažu individualne nutricionističke konsultacije za postavljanje ciljeva i kreiranje planova ishrane koji odgovaraju njihovim specifičnim potrebama.</p>
                    </SquareImageLeft>
                    <h2><strong>Detalji o predavanjima</strong></h2>
                    <p>Naša predavanja osmišljena su tako da gostima pruže praktične savete i korisne informacije koje mogu lako primeniti u svakodnevnom životu. Predavanja su dostupna uživo u vašem hotelu ili onlajn, preko platforme po vašem izboru, što omogućava fleksibilnost i pristupačnost za sve tipove gostiju.</p>
                    <h3><strong>„Ishrana za doba anksioznosti - veza stresa i hrane“ (1h)</strong></h3>
                    <p>Ovo predavanje se fokusira na razumevanje stresa uopšte, otkrivajući njegove različite vrste i uzroke. Gosti će naučiti kako stres utiče na organizam i kako se kroz pravilnu ishranu i nekoliko lakih metoda mogu ublažiti njegovi negativni efekti.</p>
                    <SquareImage src={ThirdImage} alt="Description">
                    <h3><strong>„Nije tvoja krivica, jeste tvoja odgovornost“ (1h)</strong></h3>
                    <p>Gosti će saznati kako funkcioniše njihovo telo na biohemijskom nivou i kako svakodnevne odluke utiču na njihovo zdravlje i nivo energije. Na jednostavan i razumljiv način, ovo predavanje pruža praktične savete za poboljšanje ishrane i navika.</p>
                    <h3><strong>„Ishrana u modernom svetu - ishrana i navike I“ (2h)</strong></h3>
                    <p>Tokom ovog predavanja gosti će se upoznati sa izazovima moderne ishrane i uticajem industrijske obrade hrane na naše zdravlje. Obradićemo najčešće mitove o ishrani i dati korisne savete za izgradnju boljih navika u skladu sa modernim tempom života.</p>
                    <h3><strong>„Psihologija ishrane - Ishrana i navike II“ (2h)</strong></h3>
                    <p>U ovom predavanju istražujemo psihološke faktore koji utiču na ishranu, uključujući ulogu emocija, stresa i svakodnevnih navika. Gosti će saznati kako razviti zdrav odnos prema hrani i naučiti o efektivnim metodama kontrole težine koje su zasnovane na istraživanjima.</p>
                    </SquareImage>
                    <h2><strong>Detalji o personalnim sastancima</strong></h2>
                    <p>Personalni sastanci su osmišljeni da gostima pruže prilagođeno nutricionističko savetovanje, gde mogu definisati sopstvene ciljeve u vezi sa ishranom i zdravljem i raditi na njima uz stručno vođenje. Sastanci uključuju postavljanje ciljeva, prilagođene savete za ishranu i plan za održive promene. Mogu se održavati uživo ili onlajn.</p>
                    <p><strong>Praktične koristi nakon sastanaka:</strong> Na kraju svakog sastanka gosti dobijaju praktične preporuke i savete koje mogu lako primeniti. To može biti lista jednostavnih ciljeva, mapa puta promena ili ključni saveti o problematičnim aspektima ishrane i navika.</p>
                    <p><strong>Optimalan broj sastanaka:</strong> Jedan sastanak traje sat vremena, a minimum za postizanje efikasnih promena je tri sastanka. Iako svako ima svoj ritam, verujem da pravi rezultati dolaze sa postepenim vođenjem i mogućnošću dodatnih unapređenja.</p>
                    <h2><strong>Cene usluga</strong></h2>
                    <p><strong>Predavanja:</strong>
                    </p>
                    <ul>
                        <li>
                            <p>Jednosatno predavanje:<strong> 150 EUR</strong></p>
                        </li>
                        <li>
                            <p>Dvosatno predavanje:<strong> 300 EUR</strong></p>
                        </li>
                    </ul>
                    <p><strong>Personalni sastanci:</strong>
                    </p>
                    <ul>
                        <li>
                            <p>Cena po sastanku (1h):<strong> 40 EUR</strong></p>
                        </li>
                        <li>
                            <p>Dugoročna saradnja: Otvoreni smo za mogućnost dugoročnog angažmana sa hotelom, gde bi sastanci sa gostima bili deo ugovorenog aranžmana.</p>
                        </li>
                    </ul>
                    <p>Obogatite boravak vaših gostiju kroz edukativna predavanja i prilagođene konsultacije, uz mogućnost dugoročne saradnje. Kontaktirajte nas kako bismo dogovorili najbolju opciju za vaš hotel.</p>
                    <ul>
                        <li>
                            <p><strong>E-mail:</strong>  <a target="_blank" rel="noopener noreferrer nofollow" href="mailto:dusan@aragonski.rs">dusan@aragonski.rs</a>
                            </p>
                        </li>
                        <li>
                            <p><strong>Telefon:</strong> +381-66/58-22-799</p>
                        </li>
                    </ul>
                     
                </Container>
                <div style={{ display: 'flex', flexWrap:'wrap',  justifyContent:'center' }}> <Links></Links> </div>
               </div>
    </div>
)
}
export default Hotels;