import { Container } from '@mui/system'
import React, { Component } from 'react'
import Header from '../common/header'
import BioImage from '../Images/Bio01.webp'
import BookButton from '../common/book'
import BioImageSmall from '../Images/Bio02.jpg'

class Biography extends Component {
    render() {
        return (
            <div style={{ minHeight: '100VH' }}>
                <Header>
                </Header>
                <div className='horizontal-padding-for-phone'>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%'}}>
                        <Container>
                            <h1 style={{fontSize: '2.5rem', textAlign:'left' }} className="titles">Pozdrav</h1>
                            <div className='hide-element-on-big'><img src={BioImageSmall} alt='Nutricionista kragujevac šumadija dušan aragonski' className='blogimage'></img></div>
                            <p className="white_text">Dobro došao na moj sajt, siguran sam da ćeš ovde pronaći mnogo korisnih informacija koje mogu značajno da poboljšaju tvoj život (svi moji blogovi su edukativni). Ali prvo par stvari o meni.</p>
                            <p className="white_text">Diplomirao sam Biohemiju na Univerzitetu u Novom Sadu 2016. i stekao veoma dobru osnovu za razumevanje mehanizama u našem telu i navika koje čoveku mogu pomoći da živi kvalitetniji i zdraviji život. Tokom studiranja sam uvideo značaj ishrane na čovekovo dobrostanje, što me je podstaklo da nastavim sa detaljnim proučavanjem nutricije i postanem specijalista u ovoj oblasti. Tri godine kasnije počeo sam da radim sa ljudima na unapređenju ishrane, a 2021. sam završio master ishrane i suplementacije na medicinskom fakultetu u Kragujevcu. Sa zadovoljstvom, godinama pomažem ljudima da promene svoje navike na lak i njima prirodan način, koji je pre svega efikasan i održiv.</p>
                            <p className="white_text">Moj pristup nutriciji je jedinstven jer ne uključuje “Tvrde restrikcije”, što znači da nema zabrana i moranja. Nakon godina rada sa ljudima, napravio sam dobar i održiv pristup poboljšanju ishrane, koji nazivam “Meke restrikcije”. One vode ka ostvarenju ciljeva, bez pritiska da se život menja iz korena. </p>
                            <div style={{display:'flex'}}>
                                <img src={BioImage} alt="Nutricionista kragujevac šumadija dušan aragonski" style={{maxHeight:'48vH', marginRight: '20px' }} className='hide-element-on-small'/>
                                <div>
                                    <p className="white_text">Umesto “Ne smeš da jedeš slatkiše” nudim “Da li bi slatkiši samo nakon ručka zadovoljili tvoju (normalnu) potrebu za slatkim?”. Umesto “Moraš da jedeš ovo za doručak” nudim “Koji od ovih 20 dobrih doručaka tebi deluje interesantno?”. Pošto je promena ishrane jedna od najteže promenljivih navika, moj posao nije samo da te edukujem, već i da ti pomognem da vidiš gde si sada, šta je trenutni problem, a gde zapravo želiš da budeš.</p>
                                    <p className="white_text">Tvoje prisustvo ovde znači da si već napravio/la prvi korak! Želiš li da napraviš i drugi? Obećavam ti da neće biti teško, nekoliko malih promena će ti odmah olakšati svakodnevicu, a nakon nekog vremena doći ćemo do ostvarenja tvojih ciljeva. Kontaktiraj me da popričamo o tvojim ciljevima i putu da ih postigneš.</p>
                                    <BookButton/>
                                    <p className="white_text">Moje ime je Dušan Aragonski, tu sam za sva tvoja pitanja</p>
                                </div>
                            </div> 
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default Biography