import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';
import FirstImage from '../blog_images/mediteranska_ishrana/img1.jpg'
import SecondImage from '../blog_images/mediteranska_ishrana/img2.jpg'
import ThirdImage from '../blog_images/mediteranska_ishrana/img3.jpg'
import FourthImage from '../blog_images/mediteranska_ishrana/img4.jpg'
import FifthImage from '../blog_images/mediteranska_ishrana/img5.jpg'
import SixthImage from '../blog_images/mediteranska_ishrana/img6.jpg'
import SeventhImage from '../blog_images/mediteranska_ishrana/img7.jpg'
const BlogPost5 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere'}}>
<p>Mediteranska ishrana po mi&scaron;ljenu brojnih stručnjaka, i mom mi&scaron;ljenju, spada u najzdravije režime ishrane koji postoje. Iako često možete videti termin mediteranska dijeta, reč dijeta lo&scaron;e opisuje ovu ideju. Mediteranska ishrana zami&scaron;ljena je kao stil života koji najveći doprinos ima ukoliko se primenjuje dugi niz godina. Saveti vezani za mediteranski režim ishrane često govore o stvarima koje nisu striktno vezane za ishranu već i za mentalno zdravlje i sreću. Tako se, na primer, predlaže da barem jedan obrok dnevno jedete sa porodicom ili prijateljima, da imate svakodnevno laganu fizičku aktivnost kao i da uživate u suncu kad god je to moguće.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<h2>&Scaron;ta je mediteranska ishrana?</h2>
<p>Generalno govoreći, mediteranska ishrana je način pripreme hrane, odabira namirnica i njihove konzumacije karakterističan za zemlje koje izlaze na Mediteransko more poput Grčke i Italije. Veliko interesovanje za ovaj režim ishrane počelo je pre oko &scaron;ezdeset godina, kada je zapaženo nesrazmerno ređe oboljevanje od kardio-vaskularnih bolesti među populacijom koja se hranila na ovaj način u odnosu na razvijene zemlje, npr. Amerike i Severne Evrope.</p>
<p>Ne postoji jasna definicija namirnica koje se koriste u mediteranskoj ishrani po&scaron;to na mediteranu postoji veliki broj zemalja i jo&scaron; veći broj gradova u kojima ljudi različito jedu. Postoje neka načelna pravila, ali se ova ishrana lako prilagođava čoveku ili podneblju na kome živi.</p>
<Conclusion>Mediteranska ishrana je fleksibilan i prilagodljiv način ishrane koji se fokusira na sveže, neprerađene namirnice i tradicionalne metode pripreme hrane.</Conclusion>
<h2>&Scaron;ta uključuje mediteranska ishrana?</h2>
<p>Bazira se na biljkama, a ne na mesu. Crveno meso se konzumira jednom do dva puta nedeljno, a ostalih dana biva zamenjeno piletinom, pasuljem, ribom ili drugom hranom bogatom proteinima.</p>
<p>Mediteransku ishranu odlikuje veliki, svakodnevni unos voća i povrća. Akcenat je na minimalnoj termičkoj obrati namirnica, kako bi se zadržali svi vitamini, i različitim bojama na tanjiru, kako bi se obezbedili svi nutrijenti. Prema nekim izvorima u pogodno povrće se ne ubraja krompir zbog visokog energetskog i ugljenohidratnog sastava.</p>
<p>Velika stavka mediteranske ishrane je zamena lo&scaron;ih masti zdravijim. Treba izbegavati trans i zasićene masti, a zameniti ih nezasićenim. Ova promena značajano umanjuje rizik od kardiovaskularnih bolesti. Glavni izvor masti na mediteranskom režimu ishrane je maslinovo ulje. Maslinovo ulje bogato je nezasićenim mastima koje pozitivno utiču na nivo dobrog holesterola dok istovremeno smanjuju nivo lo&scaron;eg. Dodatno, omega 3 masne kiseline dobijaju se konzumacijom ribe koja je veoma zastupljena u mediteranskoj ishrani. Posebno bogate omega 3 masim kiselinama su ribe hladnih mora, poput haringe, tune i lososa.</p>
<SquareImage src={SecondImage} alt="Description">
<p>Prema većini izvora predlaže se unos celih, integralnih žitarica, kao i zamena hleba od belog bra&scaron;na nekim kvalitetnijim. Ne postoje jasne smernice koje žitarice su poželjne, tako da to možete odabrati po ukusu.</p>
<p>U mediteranskoj ishrani koristi se velika količina različitog začinskog bilja. Ovo ne samo da pobolj&scaron;ava ukus hrane, nego i donosi zdravstvene benefite po&scaron;to većina začinskog bilja blagotvorno utiče na neki od organa ili sistem organa. Dodatno, kori&scaron;ćenje začina smanjuje potrebu za kuhinjskom solju koja se smatra &scaron;tetnom po srce.</p>
<p>Izbegava se sva visoko prerađena hrana, kao i hrana sa velikom količinom dodatih &scaron;ećera. Ovde spadaju sokovi, slatki&scaron;i, grickalice, procesuirano meso poput: kobasica, salama, vir&scaron;li itd., belo bra&scaron;no i prerađene masti i ulja, poput margarina i putera.</p>
<p>Jaja, sirevi, jogurti i ostali mlečni proizvodi nisu zabranjeni, ali se uglavnom preporučuje manji unos. Ukoliko želite da pređete na mediteranski način ishrane preporučuju se mlečni proizvodi sa manjim procentom masti, na primer grčki jogurt, i izbegavanje dodatih &scaron;ećera u vidu voćnog jogurta na primer.</p>
</SquareImage>
<p>Ljudima je često najzanimljivija stvar vezana za mediteransku ishranu preporuka konzumacije crvenog vina. Naravno voda treba da bude glavni i osnovni izvor hidracije, ali je ča&scaron;a vina dnevno dozvoljena. Preporuka je, takođe, da se vino uvek pije u dru&scaron;tvu. Kafa i biljni čajevi su dozvoljeni, ali treba izbegavatai dodavanje zaslađivača, &scaron;ećera i meda.</p>
<div className="conclusion"><p className='conclusion_content'>Mediteranska ishrana nije jasno definisana, ali su generalne smernice veliki unos voća i povrća, umeren unos jaja, mlečnih proizvoda i piletine, i mali unos crvenog mesa. Osnovni izbor masti treba da bude maslinovo ulje.</p></div>
<h2>Kome je mediteranska ishrana namenjena?</h2>
<p><a rel="nofollow" href="https://www.cambridge.org/core/journals/public-health-nutrition/article/mediterranean-diet-science-and-practice/C383082DF00DDFE6475D0B8614EB0BE9">Studije</a> su pokazale da pojedinci koji pređu na mediteranski režim ishrane imaju značajne zdravstvene benefite. Oni se pre svega ogledaju u prevenciji kardiovaskularnih bolesti i dijabetesa tipa dva. U nastavku teksta će biti vi&scaron;e reči o pozitivnim efektima ove ishrane na zdravlje i pojedine bolesti.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<p>Iz ovih razloga lekari najče&scaron;će preporučuju ovaj režim ishrane ljudima koji već imaju nekih problema sa kardiovaskularnim sistemom ili imaju veliku sklonost ka njima, kao i ljudima koji imaju insulinsku rezistenciju, predijabetično stanje ili dijabetes tipa 2, po&scaron;to ovom ishranom mogu ublažiti simptome. Međutim, mediteranska ishrana je najbolja kao preventivna mera, a nema zabeleženih negativnih efekata, tako da se preporučuje svima koji birinu o svom zdravlju.</p>
<p><a rel="nofollow" href="https://www.liebertpub.com/doi/abs/10.1089/met.2010.0031">Pokazala</a> je dobre rezultate pri smanjivanju telesne težine, posebno ako je udružena sa povećanom fizičkom aktivno&scaron;ću i ograničenim unosom kalorija. Iz ovog razloga preporučuje se ljudima koji imaju problem sa kontrolom telesne težine, kao efikasan način za mr&scaron;avljenje i održavanje kilaže.</p>
<p>Zbog starenja populacije u celom modernom svetu, povećavaju se medicinski tro&scaron;kovi lečenja. Najče&scaron;ća oboljenja su upravo ona kod kojih mediteranski tip ishrane može pomoći, pa je sve vi&scaron;e <a rel="nofollow" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7692768/">radova</a> koji govore o ekonomskim benefitima, smanjivanjem tro&scaron;kova lečenja populacije koja se pridržava ovog režima. </p>
</SquareImageLeft>
<p>Iako sve populacije imaju jednake benefite prelaska na mediteransku ishranu, to nije uvek moguće zbog regionalnih, socijalnih i kulturolo&scaron;ko &ndash; verskih faktora. Mediteranska ishrana nije jeftina po&scaron;to je većina namirnica visokog kvaliteta &scaron;to ima uticaja na cenu, a dodatno, sveže voće i povrće nije dostupno svuda i u svako doba godine. Re&scaron;enje ovog problema je pravljenje lokalizovanih režima ishrane koji bi postigli iste efekte uz kori&scaron;ćenje lokalnih mogućnosti i namirnica. Primer je DASH dijeta, koja je napravljena po uzoru na mediteransku ishranu, ali prilagođena američkom podneblju.</p>
<div className="conclusion"><p className='conclusion_content'>Svima koji žele da žive zdravije, a posebno gojaznim i ljudima sa kardiovaskularnim problemima.</p></div> 
<h2>Za&scaron;to mediteranska ishrana?</h2>
<p>Mediteranska ishrana je po mi&scaron;ljenju mnogih najbolja i najzdravija ishrana na svetu. Progla&scaron;ena je najboljom ishranom po ukupnom skoru od strane <a rel="nofollow" href="https://health.usnews.com/best-diet/best-diets-overall">U.S. News</a>. Takođe, 2013. godine mediteranska ishrana u&scaron;la je u reprezentativnu listu nematerijalnog kulturnog nasleđa čovečanstva organizacije <a rel="nofollow" href="https://ich.unesco.org/en/RL/mediterranean-diet-00884">UNESCO</a>.</p>
<p>Smernice za pridržavanje mediteranskog režima ishrane veoma su jednostavne. Većina ljudi ne računa kalorije i ne vodi računa o količini unete hrane, već samo o tipu namirnica i vremenu jela. Iz ovog razloga svako ko je voljan da započne zdraviji život, može bez mnogo muke da započne ovaj režim već danas (mada uglavnom od sutra).</p>
<RectangleImage src= {FourthImage} alt="Description"></RectangleImage>
<p>Dok postoje brojni receprti koji su u skladu sa mediteranskom ishranom, bilo &scaron;ta &scaron;to pripremate će zapravo biti dobro, dokle god nema previ&scaron;e mesa, &scaron;ećera i masti, raznobojno je i u osnovi mu je povrće. Ukoliko se malo potrudite veoma brzo možete naučiti kako da smi&scaron;ljate i pripremate hranu koja će biti u skladu sa ovim režimom. Naravno, za sva pitanja i savete možete mi se obratiti.</p>
<div className="conclusion"><p className='conclusion_content'>Zato &scaron;to je progla&scaron;ena za najzdraviju ishranu na svetu od vi&scaron;e nezavisnih strana, laka je i provereno daje rezulatate.</p></div> 
<h2>Zdravstveni benefiti mediteranske ishrane</h2>
<p>Tokom prethodnih 60 godina obavljen je ogroman broj istraživanja u kojima su ispitivani efekti mediteranskog režima ishrane na različite bolesti i stanja. Najveći broj istraživanja obavljen je u oblasti bolesti srca, ali druga istraživanja ukazuju na pobolj&scaron;anje mnogih drugih stanja.</p>
<h2>Kardiovaskularne bolesti</h2>
<SquareImageLeft src={FifthImage} alt="Description">
<p>Kardiovaskularne bolesti su <a rel="nofollow" href="https://www.who.int/health-topics/cardiovascular-diseases/#tab=tab_1">najče&scaron;ći</a> uzročnik smrti &scaron;itom sveta, pri čemu se procenjuje da uzrokuju 31% svih smrtnih slučajeva. Iz ovog razloga, nalaženje ishrane koja smanjuje rizik od obolevanja predstavlja prioritet medicinske nutricionistike već vi&scaron;e od pola veka.&nbsp;</p>
<p>Prema <a rel="nofollow" href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0159252">jednom istraživanju</a> mediteranska ishrana smanjuje verovatnoću stvaranja komplikacija pri kardiovaskularnim obolenjima za 37%, a ove podatke je ispitala i potvrdila <a rel="nofollow" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7692768/">druga studija</a>. Pored toga rezultati velikog broja <a rel="nofollow" href="https://academic.oup.com/eurpub/article/28/5/955/5050889">istraživanja</a> ukazuju na potencijalne pozitivne efekte ove ishrane pri prevenciji srčanog udara, zastoja srca, koronarne bolesti i &scaron;loga.</p>
<p>Jo&scaron; <a rel="nofollow" href="https://www.cambridge.org/core/journals/public-health-nutrition/article/mediterranean-diet-science-and-practice/C383082DF00DDFE6475D0B8614EB0BE9">jedno istraživanje</a> govori da ukoliko su mediteranskoj ishrani dodate redovna fizička aktivnost i odsustvo duvana, sklonost ka koronarnoj bolesti smanjuje se za vi&scaron;e od 80%, dok se sklonost ka &scaron;logu smanjuje za 70%.</p>
</SquareImageLeft>
<p><a rel="nofollow" href="https://www.escardio.org/">Evropska zajednica kardiologa</a>, <a rel="nofollow" href="https://www.acc.org/">Američki koledž za kardiologiju</a> i <a rel="nofollow" href="https://www.heartfoundation.org.au/">Nacionalna fondacija Australije za srce</a> predlažu mediteransku ishranu kao način smanjenja rizika od kardiovaskularnih bolesti.</p>
<div className="conclusion"><p className='conclusion_content'>Postoje veoma čvrsti dokazi o pozitivnom uticaju mediteranske ishrane na kardiovaskularne bolesti.</p></div>
<h2>Telesna težina i BMI </h2>
<SquareImage src={SixthImage} alt="Description">
<p><a rel="nofollow" href="https://www.who.int/health-topics/obesity#tab=tab_1">Prema podacima</a> Svetske zdravstvene organizacije preko 4 miliona ljudi svake godine umre od posledica prekomerne kilaže. Gojaznost među decom uzrasta 5 do 19 godina se u poslednjih četrdesetak godina povećala vi&scaron;e od 4 puta. Svetska zdravstvena organizacija upozorava na gojaznost koja dobija razmere epidemije i zahteva &scaron;to brže re&scaron;enje. Jedno od potencijalnih re&scaron;enja može biti mediteranski režim ishrane.</p>
<p>Mediteranska ishrana ima očekivano pozitivne efekte na smanjenje telesne težine. Tačan razlog ovih efekata mogao bi biti me&scaron;avina vi&scaron;e pozitivnih aspekata ovog režima ishrane, poput malog unosa obrađenih masti i prostih &scaron;ećera, visok unos vlakana iz voća i povrća i regulacija odnosa zasićenih i nezasićenih masti.</p>
<p><a rel="nofollow" href="https://www.liebertpub.com/doi/abs/10.1089/met.2010.0031">Prvo istraživanje </a> tokom kojeg su poku&scaron;ali da izmere tačnu efikasnost mediteranske ishrane napisano je 2011. Kori&scaron;ćenjem velikog broja prethodno urađenih ispitivanja, naučnici su odredili da su ljudi na ovoj ishrani gubili u proseku 1,75 kilograma, ali kada je ishrani dodat ograničen unos kalorija srednji gubitak povećao se na 3,88 kilograma, a sa fizičkom aktivno&scaron;ću preko 4 kilograma. Dodatno su zaključili da nije bilo zabeleđženo povećanje telesne težine na mediteranskom režimu ishrane.</p>
</SquareImage>
<p><a rel="nofollow" href="https://academic.oup.com/eurpub/article/28/5/955/5050889">Druga publikacija</a> ukazuje na slične rezultate.  Upoređivanjem mediteranske ishrane sa drugom dijetom pokazani su značajno bolji rezultati. Tokom 12 meseci ispitanici su na mediteranskom režimu izgubili između 4,1 i 10,1 kilograma, &scaron;to je rezultat uporediv sa savremenim dijetama.</p>
<div className="conclusion"><p className='conclusion_content'>Mediteranska ishrana postiže uporedive rezultate sa drugim održivim dijetama, pri poku&scaron;aju gubitka kilograma.</p></div>
<h2>Kancer</h2>
<p>Kancer je drugi <a rel="nofollow" href="https://www.who.int/health-topics/cancer#tab=tab_1">glavni uzročnik smrti</a> na svetu i svaki &scaron;esti čovek premine od ove bolesti.  Predstavlja veliku grupu bolesti koje mogu zadesiti gotovo svaki organ ili tkivo u telu, i podjednako ugrožavaju mu&scaron;karce i žene. Faktori rizika su brojni i različiti, ali se mogu podeliti u tri grupe: individualni faktori, faktori životne sredine i faktori životnog stila. Dok na individualne i faktore životne sredine uglavnom ne možemo uticati, faktori životnog stila su promenjivi.</p>
<p>Smatra se da pu&scaron;enje predstavlja najveći faktor rizika i da vodi 22% smrti od kancera, međutim, ishrana, fizička aktivnost i gojaznost procenjeno utiču na izbegavanje kancera za između 30% i 50%. U zavisnosti od tipa kancera, svakodnevni unost namirnica poput kupusa, brokolija, &scaron;argarepe itd. odnosno namirnica bogatih vitaminima, mineralima i antioksidansima, može drastično uticati na prevenciju.</p>
<p>Mediteranska ishrana sadrži veliki broj namirnica bogatih supstancama koje pomažu u borbi protiv ćelijske degeneracije, koja vodi do nastanka kancera. Zbog nejasne definicije mediteranske ishrane naučnici imaju problema da odrede tačnu efikasnost u preventivi kancera, ali je <a rel="nofollow" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6770822/">vi&scaron;e studija</a> demonstriralo veliku povezanost mediteranske ishrane sa smanjenjem rizika od maligniteta.</p>
<div className="conclusion"><p className='conclusion_content'>Ukoliko posmatramo mediteransku ishranu kao stil života koji pored hrane uključuje i fizičku aktivnost, dobru telesnu težinu i odsustvo duvana, moguće je sprečiti vi&scaron;e od polovine slučajeva kancera koji se danas de&scaron;avaju.</p></div>
<h2>Ostale bolesti</h2>
<p>Sa različitim stepenom pouzdanosti podataka i različitim rezultatima <a rel="nofollow" href="https://academic.oup.com/eurpub/article/28/5/955/5050889">ispituje</a> se uticaj mediteranskog režima ishrane na brojne druge bolesti. Neke od bolesti za koje postoje indikacije da mogu biti ublažene ili preventovane mediteranskom ishranom su: astma, smanjenje kognitivnih funkcija, metabolički sindrom, reumatski artritis, dijabetes melitus 2, i dr.</p>
<p><a rel="nofollow" href="https://academic.oup.com/biomedgerontology/article/73/3/318/4736301?login=true#203003784">Istraživanje</a> obavljeno 2018. godine bavilo se uzrocima pozitivnih efekata mediteranske ishrane na zdravlje. Ispitivali su metabolizam i molekulske mehanizme i do&scaron;li do zaključaka da mediteranski tip ishrane vodi:</p>
<ul>
<li>Smanjenju masti i pozitivnom promenom njihovih efekata</li>
<li>Antiinflamatornim, antioksidativnim i antiagregacionim efektima</li>
<li>Modulaciji medijatora koji promovi&scaron;u rak (hormona i faktora rasta)</li>
<li>Promeni crevne flore</li>
</ul>

<div className="conclusion"><p className='conclusion_content'>Mediteranska ishrana ima pozitivan efekat na različite bolesti.</p></div>
<p></p>
<SquareImageLeft src={SeventhImage} alt="Description">
<h2>Primeri obroka</h2>
<ul>
<li>Doručak: Grčki jogurt sa bobicama i le&scaron;nicima</li>
<li>Ručak: Losos sa belim lukom i medom</li>
<li>Večera: Kuvano povrće</li>
<li>Doručak: Jaja sa spanaćem i paradajzom</li>
<li>Ručak: Piletina u maslinovom ulju sa belim lukom, maslinama i začinima</li>
<li>Večera: Integralna pasta i sezonska salata</li>
<li>Doručak: Kajgana sa tikvicama i paradajzom</li>
<li>Ručak: Supa od morskih plodova</li>
<li>Večera: Palenta sa pečenim patlidžanom, pečurkama i crvenom paprikom</li>
</ul>
</SquareImageLeft>
<h2>Zavr&scaron;ne reči</h2>
<p>Kada je reč o mediteranskoj ishrani, i ja sam pre samo načelno znao da se ona preporučuje ljudima koji imaju problema sa srcem i artritisom, kao i da uključuje dosta začinskih biljaka. Međutim, kada sam se inicijalno zainteresovao i dublje uronio u ovu temu postao sam svestan da su benefiti koje ovaj režim ishrane pruža mnogo veći i značajniji. Sam način ishrane bi, naravno, trebalo prilagoditi na&scaron;im prostorima i uključiti &scaron;to vi&scaron;e domaćih zdravih biljaka na primer koprivu, divlje pečurke, &scaron;ljive itd.</p>
<p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost5