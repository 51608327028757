import React from 'react';
import { Container } from '@mui/material';
import BeforeAndAfter from '../Images/nevena_pre_i_posle.jpg'
import Graphic from '../Images/graf1.png'

const CaseStudy = () => {
    return (
        <Container sx={{ marginY: '20px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '25px' }}>
        
            <p>U današnjem užurbanom svetu, mnogi se bore sa održavanjem zdravih navika i postizanjem željene težine. Nevena (36), zaposlena u proizvodnji i majka troje dece, nije bila izuzetak. Pre nego što je započela saradnju sa mnom u januaru, osećala se preopterećeno, imala je loše navike u ishrani i manjak samopouzdanja. Njen odnos prema zdravlju bio je narušen, a prethodni pokušaji da smrša uz pomoć tableta i dijeta nisu dali dugoročne rezultate.</p>
            <p>Nevena je imala haotičan raspored obroka, često je preskakala obroke ili se oslanjala na brzu hranu i peciva. Njen tipičan dan je uključivao kifle i jogurt za doručak, a završavao se prženicama, mekikama ili lisnatim testom. Osim viška kilograma, Nevena se borila i sa manjkom energije, lošim raspoloženjem i osećajem beznadežnosti kada je reč o njenom zdravlju.</p>
            <h3>Prekretnica je nastupila kada je odlučila da potraži stručnu pomoć.</h3>
            <p>Na prvom sastanku, pažljivo sam saslušao Nevenine brige i ciljeve. Zajedno smo analizirali njene navike, izazove sa kojima se suočava i mogućnosti za promenu. Ključno je bilo da razumem njen stil života, rad u tri smene i ograničeno vreme za pripremu hrane. Osim toga, uočio sam da se Nevena bori sa previše informacija o ishrani i manjkom samopouzdanja da može da ostvari trajne promene.</p>
            <h3>Moj pristup se zasniva na postepenim, održivim promenama prilagođenim individualnim potrebama. </h3>
            <p>Umesto rigoroznih isključenja namirnica i ograničenja količine kalorija u toku dana, fokusirali smo se na uobročavanje, izbor zdravijih namirnica i promenu navika korak po korak. Zajedno smo kreirali dva rasporeda obroka prilagođena njenim smenama, sa fokusom na četiri redovna obroka i izbegavanje grickalica između obroka.</p>
            <p>Prvi korak bio je da uvedemo redovne obroke u Nevenin dan. Dogovorili smo se oko okvirnog vremena za doručak, ručak, večeru i užinu, uzimajući u obzir njene smene i pauze na poslu. Naglasio sam da je ovo samo predlog i da možemo da ga prilagođavamo u skladu sa njenim obavezama i osećajima.</p>
            <h3>Nevena je bila izuzetno motivisana. </h3>
            <p>Redovno smo se sastajali na konsultacijama, a između sastanaka sam joj slao savete i pružao podršku. Na svakom susretu smo analizirali napredak, prilagođavali plan i rešavali nove izazove. Koristeći tehnike motivacionog intervjuisanja, ohrabrivao sam je da istraži svoje motive za promenu i osnažio je da veruje u sebe.</p>
            <p>Na drugom sastanku u februaru, prilagodili smo vreme obroka i dodali zdrave opcije za užinu, poput voća, orašastih plodova ili jogurta. Takođe smo razgovarali o slatkišima i nezdravoj hrani. Umesto da ih potpuno zabranimo, dogovorili smo se da ih Nevena jede umereno i posle glavnih obroka, kako bi se smanjio njihov uticaj na nivo šećera u krvi.</p>
            <p>U martu smo se fokusirali na izbacivanje hleba i slatkiša iz večere, kako bismo produžili period ketoze tokom noći. Nevena je za doručak počela da bira jaja, ovsene pahuljice sa voćem ili grčki jogurt, dok je za ručak jela kuvana jela sa mesom i povrćem.</p>
            <p>Tokom aprila i maja, radili smo na proširivanju izbora zdravih namirnica i uvođenju probiotika za poboljšanje crevne flore. Nevena je naučila da sama osmišlja zdrave obroke i da planira unapred kako bi imala zdravu hranu na poslu.</p>
            <h3>Rezultati su bili impresivni! </h3>
            <p>Nevena je za šest meseci izgubila 18 kilograma, smanjila procenat masti sa 42.6% na 40.7% i povećala mišićnu masu. Njen nivo viscelarnih masti se značajno smanjio sa 8 na 6, a krvni parametri su se poboljšali.</p>
            <img src={Graphic} alt='Grafik kako izgubiti 18 kilograma trajno i efikasno'className="case_study_image" />
            <p>Ali, promene nisu bile samo fizičke. Nevena je povratila samopouzdanje, osećala se energičnije i imala je pozitivniji stav prema životu. Naučila je da razlikuje kategorije namirnica, da donosi zdravije izbore i da osluškuje svoje telo. Čak je i pokrenula sopstveni biznis!</p>
            <p>"Dušan je stručan, podrška i pravi prijatelj", napisala je Nevena u svojoj recenziji. "Zahvaljujući njemu, promenila sam svoj život nabolje."</p>
            <img src={BeforeAndAfter} alt='Pre i posle programa ishrane neverovatni rezultati'className="case_study_image" />
            
            <p>Nevenina priča je dokaz da uz stručnu podršku i personalizovani pristup, možeš postići trajne promene i živeti zdravijim i ispunjenijim životom.</p>
            <h2>Da li ste i vi spremni za promenu? </h2>
            <p>Zakažite besplatne konsultacije već danas i otkrijte kako mogu da vam pomognem da postignete svoje ciljeve u vezi sa ishranom i zdravljem.</p>
        </Container>
    );
};

export default CaseStudy;