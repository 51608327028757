import { Container } from '@mui/system';
import React from 'react';
import Header from '../common/header';
import Blogs from './blog_texts.json';
import Carousel from './carousel/blog_carousel';
import { Helmet } from 'react-helmet';

const ScienceDetail = () => {
    const url = window.location.pathname;
    window.scrollTo(0, 0);
    const BlogPost = url.split("/").pop();
    const BlogTextComponent = require(`./blog_html/blog_${BlogPost}.js`).default;

    const { title, text_short, image } = Blogs.articles[BlogPost];

    return (
        <div style={{ minHeight: '100VH' }}>
            <Helmet>
                <title>{title} | Aragonski Nutrition</title>
                <meta name="description" content={text_short} />
                <meta property="og:title" content={`${title} | Aragonski Nutrition`} />
                <meta property="og:description" content={text_short} />
                <meta property="og:image" content={`https://aragonski.rs/${image}`} />
                <meta property="og:url" content={`https://aragonski.rs${url}`} />
                <meta property="og:type" content="article" />
            </Helmet>
            <div>
                <Header />
                <h1 style={{ fontSize: '3.2rem', textShadow: '3px 3px 3px #214751', marginBottom: '3VH' }}>
                    {title}
                </h1>
            </div>

            <div style={{ width: '100HV', backgroundColor: '#fcf9f3' }}>
                <Container style={{ display: 'flex', flexDirection: 'row' }}>
                    <BlogTextComponent />
                </Container>
            </div>
            <div><Carousel /></div>
        </div>
    );
};

export default ScienceDetail;
