import React, { Component } from 'react'
import Header from '../common/header'
import MapSection from './map'
import Links from '../common/links'
import { Helmet } from 'react-helmet'

class Contact extends Component {
    render() {
        return (
            <div style={{ minHeight: '100VH' }}>

                <Helmet>
                        <title>Kontaktirajte Dušana Aragonskog | Nutricionista Kragujevac</title>
                        <meta
                        name="description"
                        content="Kontaktirajte nutricionistu Dušana Aragonskog za stručne savete o ishrani. Pogledajte broj telefona, društvene mreže i lokaciju ordinacije u Kragujevcu."
                        />
                        <meta name="keywords" content="Kontakt nutricionista Kragujevac, Dušan Aragonski kontakt, saveti o ishrani, ordinacija Kragujevac, nutricionističke konsultacije" />

                        {/* Open Graph Meta Tags */}
                        <meta property="og:title" content="Kontakt Dušana Aragonskog - Nutricionista" />
                        <meta property="og:description" content="Posetite ordinaciju ili kontaktirajte nutricionistu Dušana Aragonskog putem telefona ili društvenih mreža za savet o ishrani." />
                        <meta property="og:image" content="https://aragonski.rs/helmet-images/contact-image.jpg" />
                        <meta property="og:url" content="https://aragonski.rs/contact" />
                        <meta property="og:type" content="website" />
                </Helmet>

                <Header></Header>
                <h1 style={{ color: '#c8cac0', fontSize: '4rem', marginBottom:'24px' }} className="main">Kontaktiraj me</h1>
                <div style={{ display: 'flex', flexWrap:'wrap', justifyContent:'center', marginBottom:'20px' ,marginRight:'20px'}}><Links></Links></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', borderRadius: '25px'}}>
                    <MapSection location={location} zoomLevel={19} />
                </div>
            </div>
        )
    }
}

const location = {
    address: 'Janka Veselinovica 63 Kragujevac',
    lat: 44.01819,
    lng: 20.91353,
  }

export default Contact